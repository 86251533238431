import { useEventListener } from '@vueuse/core';
import { isHtmlElement } from '../../../js/helpers/type-guards/isHtmlElement';

const COLLAPSE_CLASS = '.collapse-component';
const HEADER_CLASS = '.collapse-component-header';
const CONTENT_CLASS = '.collapse-component-content';

const getContentHeight = (element: HTMLDivElement): number | null => {
    const contentElement = element.querySelector<HTMLDivElement>(CONTENT_CLASS);
    const LINE_HEIGHT = 24;

    const height = contentElement.offsetHeight;
    const extraLine = element.dataset.extraLine ? LINE_HEIGHT * 2 : 0;

    return height + extraLine || null;
};

const hideCollapseContent = (element: HTMLDivElement) => {
    const contentElement = element.querySelector<HTMLDivElement>(CONTENT_CLASS);

    if (contentElement) {
        contentElement.style.maxHeight = '0';
        contentElement.style.opacity = '1';
        contentElement.style.position = 'relative';
    }
};

const addCollapseLogic = (event: MouseEvent, element: HTMLDivElement, contentHeight: number) => {
    if (!isHtmlElement(event.target)) {
        return;
    }

    const clickedElement = event.target;
    const contentElement = element.querySelector<HTMLDivElement>(CONTENT_CLASS);

    if (contentElement && clickedElement.closest(HEADER_CLASS)) {
        contentElement.style.maxHeight = contentElement.style.maxHeight === '0px'
            ? `${contentHeight}px`
            : '0px';
        element.classList.toggle('collapse-container--open');
    }
};

const addCollapseLogicToElements = () => {
    const collapseElements = document.querySelectorAll<HTMLDivElement>(COLLAPSE_CLASS);

    collapseElements.forEach((element) => {
        const contentHeight: number | null = getContentHeight(element);

        if (contentHeight !== null) {
            hideCollapseContent(element);
            useEventListener(
                element,
                'click',
                (event) => addCollapseLogic(event, element, contentHeight),
            );
        }
    });
};

useEventListener(document, 'DOMContentLoaded', addCollapseLogicToElements);
